import React from 'react';
import styled from 'styled-components';

const PageDiv = styled.div`
  min-height: 100%;
  display: flex;
`;
const Section = styled(PageDiv)`
  flex-direction: column;
`;

export const Page = ({ title, children }) =>(
  <PageDiv className="docs-page bp3-fill">
    <Section className="docs-section bp3-fill">
      {title && <h1 className="bp3-heading docs-title">{title}</h1>}
      {children}
    </Section>
  </PageDiv>
);
