import React from 'react';
import {NonIdealState} from '@blueprintjs/core';

export const Forbidden = () => {
    return (
        <NonIdealState
            icon="disable"
            title="Forbidden"
            description="You cannot access this resource"
        />
    )
};
