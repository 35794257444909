export const SCOPE = {
  PAYOUT: {
    _ROOT: 'payout',
    SETTLEMENT: {
      ENABLE: 'payout.settlement.enable',
    }
  },
  TRANSACTION: {
    _ROOT: 'transaction',
  },
  RISK_MANAGEMENT: {
    _ROOT: 'risk-management',
  },
  OPERATION: {
    _ROOT: 'operation',
  },
  ADMIN: {
    _ROOT: 'admin',
  },
  IT: {
    _ROOT: 'it',
  },
  FINANCE: {
    _ROOT: 'finance',
  }
};

export const SIGNUP_REDIRECT_SEARCH = "?redirect=signup";