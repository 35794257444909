import React from 'react';
import {NonIdealState} from '@blueprintjs/core';

export const NotFound = () => {
    return (
        <NonIdealState
            icon="zoom-out"
            title="404"
            description="Page not found"
        />
    )
};
