export const MAPUtils = {
    hasExponent(currency) {
        return currency !== 'JPY' && currency !== 'KRW';
    },
    amountAddDot(amount, currency) {
        if (MAPUtils.hasExponent(currency)) {
            return amount / 100;
        }
        return amount;
    },
    amountRemoveDot(amount, currency) {
        if (MAPUtils.hasExponent(currency)) {
            return amount * 100;
        }
        return amount;
    },
    verifyEmail(value) {
        return !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
    },
    validateNumber(value) {
        return !/^[0-9]\d*(\.\d+)?$/.test(value);
    },
};
