import React from 'react';
import {Classes, Overlay, Spinner} from "@blueprintjs/core";
import {observer} from "mobx-react-lite";

import {spinnerStore} from '../../stores';

export const SpinnerContainer = observer(() => {
  return spinnerStore.count ? (
    <Overlay isOpen key="spinner">
      <div className={Classes.DIALOG_CONTAINER}>
        <Spinner/>
      </div>
    </Overlay>
  ) : null;
});
