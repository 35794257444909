import React from 'react';
import {observer} from "mobx-react-lite";
import {Alert} from "@blueprintjs/core";
import nl2br from 'react-nl2br';

import {alertStore} from "../../stores";

export const AlertContainer = observer(({className}) => {
  return alertStore.next ? (
    <Alert
      {...alertStore.next}
      children={<p key="text">{nl2br(alertStore.next.text)}</p>}
      isOpen
      className={className}
    />
  ) : null;
});
