import {createContext} from 'react';
import {decorate, observable} from 'mobx';

class SpinnerStore {
  count = 0;
  inc() {
    this.count++;
  }
  dec() {
    this.count--;
  }
}

decorate(SpinnerStore, {
  count: observable,
});

export const spinnerStore = new SpinnerStore();

export const spinnerStoreContext = createContext(spinnerStore);
