import React, { useContext } from 'react';
import { Classes, Dialog } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import { alertStore, dialogStore, dialogStoreContext, mainStoreContext } from "../stores";
import { SchemaFieldTypes, SchemaForm } from "./schema-form";
import { Auth } from 'aws-amplify';
import { Logger } from '../utils/logger';

const changePasswordSchema = {
    columns: 1,
    fields: {
        currPassword: {
            label: 'Current Password',
            type: SchemaFieldTypes.TEXT,
            inputType: 'password',
            required: true,
        },
        newPassword: {
            label: 'New Password',
            type: SchemaFieldTypes.TEXT,
            inputType: 'password',
            required: true,
            tips: <span>
                The password should be has more than 8 characters, must contain at least:<br />
        1 uppercase letter,<br />
        1 lowercase letter,<br />
        1 numeric digit,<br />
        but cannot contain whitespace.
      </span>,
            rules: {
                fn: (value, isEmpty) => {
                    if (!isEmpty && !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/.test(value)) {
                        return 'error';
                    }
                }
            }
        },
        confirmPassword: {
            label: 'Confirm Password',
            type: SchemaFieldTypes.TEXT,
            inputType: 'password',
            required: true,
            rules: {
                fn: (value, isEmpty, data) => {
                    if (!isEmpty && value !== data.newPassword) {
                        return 'Passwords do not match';
                    }
                }
            }
        },
    },
    buttons: [{
        text: 'SAVE',
        icon: 'floppy-disk',
        onClick: queryData => {
            Logger.writeLog('click - button - Save - update New Password');
            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, queryData.currPassword, queryData.newPassword);
                })
                .then(data =>
                    alertStore.add({ text: 'Password changed.' }),
                    dialogStore.showChangePassword = false
                )
                .catch(err => alertStore.add({ text: 'Change password fail. Current password or New password invalid.' })
                );
        },
    }],
};

export const ChangePasswordDialog = observer(() => {
    const dialogStore = useContext(dialogStoreContext);
    const mainStore = useContext(mainStoreContext);
    return dialogStore.showChangePassword ? (
        <Dialog
            isOpen
            onClose={() => dialogStore.showChangePassword = false}
            title="Change Password"
            className={mainStore.themeClass}
            canOutsideClickClose={false}
        >
            <div className={Classes.DIALOG_BODY}>
                <SchemaForm schema={changePasswordSchema} />
            </div>
        </Dialog>
    ) : null;
});
