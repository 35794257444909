import React, { useContext, useState, useEffect } from 'react';
import { Classes, Dialog } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import { MAPUtils } from '../utils';
import { alertStore, dialogStoreContext, mainStoreContext } from "../stores";
import { SchemaFieldTypes, SchemaForm } from "./schema-form";
import { Auth } from 'aws-amplify';
import { Logger } from '../utils/logger';

export const AccountSettingDialog = observer(() => {
    const dialogStore = useContext(dialogStoreContext);
    const mainStore = useContext(mainStoreContext);
    let [dataProfile, setDataProfile] = useState({});

    const AccountSettingSchema = {
        columns: 1,
        fields: {
            email: {
                label: 'Email',
                type: SchemaFieldTypes.TEXT,
                required: true,
                readonly: true,
                rules: {
                    fn: (value, isEmpty) => {
                        if (!isEmpty && MAPUtils.verifyEmail(value)) {
                            return 'Email address invalid, Please!';
                        }
                    }
                }
            },
            phone_number: {
                label: 'Phone Number',
                type: SchemaFieldTypes.TEXT,
                tips: 'Dial code + phone number. Example +655555551212'
            },
        },
        buttons: [{
            text: 'SAVE',
            icon: 'floppy-disk',
            onClick: queryData => {
                Logger.writeLog('click - button - Save - update Phone Number');
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        const attributes = {
                            email: queryData.email,
                            phone_number: queryData.phone_number ? queryData.phone_number : '',
                        };
                        Auth.updateUserAttributes(user, attributes)
                            .then(data => {
                                alertStore.add({ text: data });
                                if (data === 'SUCCESS') {
                                    dialogStore.showAccountSetting = false;
                                    setDataProfile(attributes)
                                }
                            })
                            .catch(err => alertStore.add({ text: err.message }));
                    })
                    .catch(err => alertStore.add({ text: err })
                    );
            },
        }],
    };

    useEffect(() => {
        async function fetchData() {
            var data = await Auth.currentAuthenticatedUser()
                .then(user => {
                    const att = user.attributes;
                    let data = {
                        email: att.email,
                        phone_number: att.phone_number,
                    }
                    return data;
                })
            setDataProfile(data);
        }
        fetchData();
    }, []);

    return dialogStore.showAccountSetting ? (
        <Dialog
            isOpen
            onClose={() => dialogStore.showAccountSetting = false}
            title="Edit Profile"
            className={mainStore.themeClass}
            canOutsideClickClose={false}
        >
            <div className={Classes.DIALOG_BODY}>
                <SchemaForm schema={AccountSettingSchema} defaultData={dataProfile} />
            </div>
        </Dialog>
    ) : null;
});
