import React from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";
import {observer} from "mobx-react-lite";

import {dialogStore} from "../../stores";

export const DialogContainer = observer(({ className }) => {
  const config = dialogStore.config;
  return config ? (
    <Dialog
      isOpen
      title={config.title}
      canOutsideClickClose={false}
      onClose={config.onClose}
      className={className}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{config.body}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {config.buttons.map(button => <Button
            onClick={button.onClick}
            intent={button.intent}
            key={button.text}
          >{button.text}</Button>)}
        </div>
      </div>
    </Dialog>
  ) : null;
});
