import { authStore, mainStore } from '../stores';
import { history } from "./history-holder";
import { Auth } from 'aws-amplify';
import { ls } from '../utils';
import { SIGNUP_REDIRECT_SEARCH } from '../constants';

export const OAuth = {
    setData(data) {
        //mainStore.authUser = data.user;
        authStore.authUser = data.user;
        authStore.loggedIn = true;

        mainStore.preloaded = true;
    },

    cleanStorage() {
        authStore.loggedIn = false;
        ls.clear();
    },

    async autoLogin(cb) {
        try {
            const sessionUser = await Auth.currentAuthenticatedUser();
            if (sessionUser != null) {
                OAuth.setData({ user: Auth.user });
                if (history.location.search === SIGNUP_REDIRECT_SEARCH) {
                    history.replace('/login');
                }
                return;
            }
            else {
                OAuth.cleanStorage();
                history.replace('/login');
            }

        } catch (err) {
            if (history.location.search !== SIGNUP_REDIRECT_SEARCH) {
                history.replace('/login');
            }
        }
        if (history.location.search !== SIGNUP_REDIRECT_SEARCH) {
            history.replace('/login');
        }
    },

    async logout() {
        try {
            await Auth.signOut()
            .then(data => {
            console.log("signed out...");
            })
            .catch(e => console.log("error: ", e));

            OAuth.cleanStorage();
            history.replace('/login');
        } catch (error) {
            console.log('error signing out: ', error);
        }
    },
    hasScope(scope) {
        //add role admin
        return true;
    },
};
