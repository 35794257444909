import {createContext} from 'react';
import {computed, decorate, observable} from 'mobx';

import {ls} from '../utils';

import {LOCAL_STORAGE} from '../constants';

class MainStore {
  theme = ls.get(LOCAL_STORAGE.THEME);
  toggleTheme = () => {
    this.theme = this.theme === 'dark' ? 'light' : 'dark';
    ls.set(LOCAL_STORAGE.THEME, this.theme);
  };
  get themeClass() {
    return this.theme === 'dark' ? 'bp3-dark' : null;
  };

  envVarLoaded = false;
  preloaded = false;
  secure = false;
}

decorate(MainStore, {
  theme: observable,
  themeClass: computed,

  envVarLoaded: observable,
  preloaded: observable,
  secure: observable
});

export const mainStore = new MainStore();

export const mainStoreContext = createContext(mainStore);
