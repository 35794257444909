import React, { useState } from 'react';
import { Authenticator, Text, useTheme } from "@aws-amplify/ui-react";
import { Auth, I18n } from 'aws-amplify';
import './login.scss';
import OxpayLogo from '../../assets/img/oxpay-logo.png'
import { Button, Classes, Dialog, FormGroup, Icon, InputGroup } from '@blueprintjs/core';
import { alertStore } from '../../stores';
import { history } from "../../utils/history-holder";
import { SIGNUP_REDIRECT_SEARCH } from '../../constants';

let resolveFunction
const pending = new Promise(resolve => { resolveFunction = resolve })

I18n.putVocabularies({
  en: {
    '2 validation errors detected: Value at \'userAlias\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at \'userName\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+': 'Incorrect username or password.'
  }
});

export const Login = () => {
  const [dataUser, setDataUser] = useState()
  const [code, setCode] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleVerifyCode = async () => {
    if (!code) return setError(true)
    error && setError(false)
    setLoading(true)

    try {
      await Auth.sendCustomChallengeAnswer(dataUser, code)
      resolveFunction()
      setDataUser()
      setCode()
    } catch (err) {
      alertStore.add({ text: err.message })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="login-page">
        <img src={OxpayLogo} className='logo' width={200} />
        <div className="login-section">
          <Authenticator
            initialState={history.location.search === SIGNUP_REDIRECT_SEARCH ? "signUp" : "signIn"}
            className='amplify-form'
            socialProviders={["google"]}
            signUpAttributes={['email']}
            loginMechanisms={['email']}
            services={{
              handleSignIn: async ({ username, password }) => {
                try {
                  const user = await Auth.signIn(username, password);
                  if (user?.challengeName === 'CUSTOM_CHALLENGE') {
                    setDataUser(user)
                    await pending
                    const currentUser = await Auth.currentSession()
                    return currentUser
                  }

                  return user
                } catch (error) {
                  if (error.message.includes('Member must satisfy regular expression pattern')) {
                    throw new Error('Incorrect username or password.');
                  }
                  throw error;
                }
              },
              handleSignUp: async (formData) => {
                if (!formData.username) {
                  throw new Error('Email is required');
                }
                
                try {
                  const result = await Auth.signUp({
                    username: formData.username,
                    password: formData.password,
                    attributes: {
                      email: formData.username
                    }
                  });
                  return result;
                } catch (error) {
                  if (error.message.includes('Member must satisfy regular expression pattern')) {
                    throw new Error('Please enter a valid email address');
                  }
                  throw error;
                }
              },
            }}
            components={{
              SignUp: {
                FormFields() {
                  const { tokens } = useTheme();

                  return <>
                    <Authenticator.SignUp.FormFields />
                    <Text color={tokens.colors.black} >
                      Your password must be have at least <br />
                      8 characters long <br />
                      1 uppercase & 1 lowercase character <br />
                      1 numeric digit <br />
                      No whitespace
                    </Text>
                  </>
                }
              }
            }}
            formFields={{
              // signIn: {
              //   username: {
              //     placeholder: 'Email',
              //   }
              // },
              signUp: {
                // username: {
                //   placeholder: 'Email',
                // },
                // Remove or hide the additional email field
                username: {
                  display: 'none'  // This hides the duplicate email field
                },
              }
            }}
          />
        </div>
        <footer className='footer'>
          <p>&#169; 2024 OxPay SG PTE LTD</p>
          <a href="mailto:support@oxpayfinancial.com" className='contact-support'>Contact Support</a>
        </footer>
      </div>

      {dataUser && <Dialog
        isOpen
        onClose={() => setDataUser(null)}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        className='verify-email-dialog'

      >
        <div className={Classes.DIALOG_BODY}>
          <div className='content'>
            <Icon icon='envelope' size={40} intent='primary' />
            <h3 className='title'>Verify your email</h3>
            <div className='description'>
              <p>We sent verification code to the email address.</p>
              <p>Please check your inbox and enter the code below.</p>
            </div>
            <FormGroup {...error && { intent: 'danger', helperText: 'This field is required.' }} className='form-input-code'>
              <InputGroup placeholder='Enter code' {...error && { intent: 'danger' }} disabled={loading} defaultValue={code} onChange={e => setCode(e.target.value)} />
            </FormGroup>
            <Button fill text='Verify Code' intent='primary' loading={loading} onClick={() => handleVerifyCode()} />
          </div>
        </div>
      </Dialog>}
    </>
  )
};