import CryptoJS from 'crypto-js';

const secretKey = "mcp-id-serect-key";

export const Encryption = {
  encrypt(value) {
    const encrypted = CryptoJS.AES.encrypt(value, secretKey).toString();
    return this.shortenBase64(encrypted);
  },

  decrypt(encryptedValue) {
    const fullBase64 = this.expandShortened(encryptedValue);
    const decrypted = CryptoJS.AES.decrypt(fullBase64, secretKey);
    return decrypted.toString(CryptoJS.enc.Utf8);
  },

  shortenBase64(base64) {
    return base64
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  },

  expandShortened(shortened) {
    const base64 = shortened
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const padding = '='.repeat((4 - base64.length % 4) % 4);
    return base64 + padding;
  }
};
