import { createContext } from 'react';
import { computed, decorate, observable } from 'mobx';
import { ls } from '../utils';

class AlertStore {
  queue = [];
  add(obj) {
    const onClose = obj.onClose;
    obj.onClose = () => {
      if (obj.isCreateUser) {
        this.queue.pop();
        onClose && onClose();
      }
      this.queue.pop();
      onClose && onClose();
    };
    if (typeof obj.text === 'function') obj.text = obj.text(obj.onClose)
    this.queue.push(obj);
  }
  get next() {
    if (this.queue.length) {
      return this.queue[0];
    }
    return undefined;
  }
}

decorate(AlertStore, {
  queue: observable,
  next: computed,
});

export const alertStore = new AlertStore();

export const alertStoreContext = createContext(alertStore);
