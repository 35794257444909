import {createContext} from 'react';
import {decorate, observable} from 'mobx';

class CacheStore {  
  currencies = [];
  currencyMapByName = {};
  currencyMapByCode = {};
  setCurrencies(currencies) {
    //countries = [{"currency":"Afghani","alphabeticCode":"AFN","numericCode":"971","exponent":2},{"currency":"Lek","alphabeticCode":"ALL","numericCode":"008","exponent":2},{"currency":"Algerian Dinar","alphabeticCode":"DZD","numericCode":"012","exponent":2},{"currency":"Kwanza","alphabeticCode":"AOA","numericCode":"973","exponent":2},{"currency":"Argentine Peso","alphabeticCode":"ARS","numericCode":"032","exponent":2}];
    this.currencies = currencies.sort((a, b) => a.name.localeCompare(b.name));
    //this.currencyMapByName = currencies.reduce((obj, item) => (obj[item.currency] = item) && obj, {});
    //this.currencyMapByCode = currencies.reduce((obj, item) => (obj[item.numericCode] = item) && obj, {});
  }

  // merchants = [];
  // merchantMapById = {};
  // setMerchants(merchants) {
  //   this.merchants = merchants;
  //   this.merchantMapById = merchants.reduce((obj, item) => (obj[item.id] = item) && obj, {});
  // }

  countries = [];
  countryMapByName = {};
  setCountries(countries) {   
    this.countries = countries;
    //this.countryMapByName = countries.reduce((obj, item) => (obj[item.name] = item) && obj, {});
  }

  gateways = [];
  setGateways(gateways) {
    this.gateways = gateways;
  }

  mastermerchants = [];
  setMasterMerchants(mastermerchants) {
    this.mastermerchants = mastermerchants;
  }
}

decorate(CacheStore, {
   currencies: observable,
  // currencyMapByName: observable,
  // currencyMapByCode: observable,

   gateways: observable,
   countries: observable,
   mastermerchants: observable,
});

export const cacheStore = new CacheStore();

export const cacheStoreContext = createContext(cacheStore);
