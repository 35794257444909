import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Page } from "../../components/page";
import { Card, Elevation } from "@blueprintjs/core";
import { appRedirect } from "../../utils/app-redirect";
import "./dashboard.scss";
import { Classes, Overlay, Spinner } from "@blueprintjs/core";
import { Logger } from "../../utils/logger";

function AppIcon(props) {
    // function ShowCard() {

    // if (props.active) {
    //     return (
    //         <a href="#" onClick={() => {
    //             if (props.active) { appRedirect((props.urlType || ''), props.setPreloadingPage); }
    //             props.setPreloadingPage(true);
    //         }}>

    //             <Card className={`app-icon ${props.iconClassName || "app-icon-map"} .modifier`}

    //                 interactive={props.active} elevation={(props.active) ? Elevation.TWO : Elevation.ZERO}>

    //             </Card>
    //         </a>

    //     );
    // }
    // else {
    //     return (
    //         <Card className={`app-icon ${props.iconClassName || "app-icon-map"} .modifier`}
    //             interactive={props.active} elevation={(props.active) ? Elevation.TWO : Elevation.ZERO}>
    //         </Card>
    //     );
    // }
    // }
    function ShowLink() {

        if (props.active) {
            return (
                <a href="#" onClick={() => {
                    if (props.active) {
                        appRedirect((props.urlType || ''), props.setPreloadingPage);
                    }
                    props.setPreloadingPage(true);
                }}>
                    {props.title}
                </a>

            );
        }
        else {
            return (
                <p>
                    {props.title}
                </p>
            );
        }
    }
    return (
        <div key="mapAdmin" className="app-item app-icon">
            {/* <ShowCard /> */}
            <h2 className={(props.active) ? "activate" : "deactivate"}>
                <ShowLink />
            </h2>
        </div>
    );
}

export const Dashboard = observer(() => {
    let [preloadingPage, setPreloadingPage] = useState(false);

    return (
        <Page title="Switch to">
            <div className="app-main">
                <AppIcon title="Merchant Portal" urlType="merchant-portal" iconClassName="app-icon-map" active={true} setPreloadingPage={setPreloadingPage} />
                <Overlay isOpen={preloadingPage}>
                    <div className={Classes.DIALOG_CONTAINER}>
                        <Spinner />
                    </div>
                </Overlay>
            </div>
        </Page>
    );
});
