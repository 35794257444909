/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_COGNITO_REGION,
    "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_POOLS_WEB_CLIENT_ID,
    "oauth": {
        "domain":  process.env.REACT_APP_COGNITO_DOMAIN,
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": process.env.REACT_APP_COGNITO_CALLBACK_DOMAIN,
        "redirectSignOut": process.env.REACT_APP_COGNITO_CALLBACK_DOMAIN,
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    // "authenticationFlowType": "CUSTOM_AUTH"
};


export default awsmobile;
