import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Classes, Dialog, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';

import { alertStore, dialogStore, dialogStoreContext, mainStoreContext } from "../stores";
import { SchemaFieldTypes, SchemaForm } from "./schema-form";
import { Auth } from 'aws-amplify';
import { Logger } from '../utils/logger';
import './styled/totp.scss'


const preferredMFA = {
    SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
    NOMFA: "NOMFA"
}

export const TOTPAuthDialog = observer(() => {
    const dialogStore = useContext(dialogStoreContext);
    const mainStore = useContext(mainStoreContext);
    const [statusMFA, setStatusMFA] = useState(Auth?.user?.preferredMFA || preferredMFA.NOMFA)
    const [otpauthURI, setOtpauthURI] = useState(null)
    const [disable2FADialog, setDisable2FADialog] = useState({
        loading: false,
        isShow: false
    })
    const [generateDialog, setGenerateDialog] = useState(false)

    const enable2FA = async () => {
        const user = Auth.user
        try {
            const status = await Auth.setPreferredMFA(user, preferredMFA.SOFTWARE_TOKEN_MFA);
            if (status === 'SUCCESS') {
                alertStore.add({ text: status })
                setStatusMFA(preferredMFA.SOFTWARE_TOKEN_MFA)
            }
        } catch (error) {
            if (error?.code === 'InvalidParameterException') {
                setupOTP()
            }
        }
    }

    const disable2FA = async () => {
        const user = Auth.user
        try {
            const status = await Auth.setPreferredMFA(user, preferredMFA.NOMFA);
            if (status === "SUCCESS") {
                alertStore.add({ text: status })
                setStatusMFA(preferredMFA.NOMFA)
            }
        } catch (error) {

        }
    }

    const setupOTP = async () => {
        const user = Auth.user
        try {
            const secretCode = await Auth.setupTOTP(user, preferredMFA);
            const str = `otpauth://totp/Oxpay Merchant Login: ${Auth.user.attributes.email}?secret=${secretCode}`
            setOtpauthURI(str)
        } catch (error) {

        }
    }

    const TOTPAuthSchema = () => {
        return {
            columns: 1,
            fields: {
                OTPCode: {
                    label: 'Scan the image below on your phone and enter the verification code.',
                    type: SchemaFieldTypes.CUSTOM,
                    renderer: (field, data, onChange, errorText) => (
                        <div style={{ display: 'flex', gap: 20, marginTop: 10 }}>
                            <div>
                                <QRCodeSVG value={otpauthURI} size={150} />
                            </div>
                            <InputGroup
                                onChange={e => onChange('OTPCode', e.target.value)}
                                type={'number'}
                                value={data['OTPCode'] || ''}
                                required
                                fill
                                placeholder='Enter 2FA verfication code here'
                            />
                        </div>
                    ),
                },
            },
            buttons: [
                {
                    text: 'Proceed',
                    onClick: async queryData => {
                        const user = Auth.user
                        try {
                            await Auth.verifyTotpToken(user, queryData.OTPCode)
                            await Auth.setPreferredMFA(user, preferredMFA.SOFTWARE_TOKEN_MFA)
                            setStatusMFA(preferredMFA.SOFTWARE_TOKEN_MFA)
                            setOtpauthURI(null)
                            if (statusMFA !== preferredMFA.SOFTWARE_TOKEN_MFA) {
                                alertStore.add({ text: "Your two-factor authentication was setup successfully" })
                            } else {
                                alertStore.add({ text: "You've updated your 2FA authentication" })
                            }
                        } catch (error) {
                            alertStore.add({ text: error.message })
                        }
                    },
                }
            ],
        };
    }

    return (
        <>
            {
                dialogStore.showSetupTOTPAuth && (
                    <Dialog
                        isOpen
                        onClose={() => dialogStore.showSetupTOTPAuth = false}
                        title="Two-Factor Authentication"
                        className={mainStore.themeClass}
                        canOutsideClickClose={false}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            {
                                statusMFA === preferredMFA.NOMFA && (
                                    <div className="main-authentication">
                                        <p className='info'>Two-factor authentication is not enabled yet</p>
                                        <p>Two-factor authentication adds an extra layer of security to your account by requiring more than just a passsword to login.</p>
                                        <div className='verify-2FA'>
                                            <Button className='purple-bg btn-2FA' onClick={() => enable2FA()}>Enable Two-Factor Authentication</Button>
                                            <p className='skip-2FA' onClick={() => dialogStore.showSetupTOTPAuth = false}>Skip 2FA</p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                statusMFA === preferredMFA.SOFTWARE_TOKEN_MFA && (
                                    <div className="main-authentication">
                                        <p className='info'>Two-factor authentication is enabled</p>
                                        <p>If you want to switch to a new device, disable two-factor authentication, then enable it again using the new device.</p>
                                        <Button intent='danger' outlined className='btn-2FA' onClick={() => setDisable2FADialog({ ...disable2FADialog, isShow: true })}>Disable 2FA</Button>
                                        <p className='generate-code' onClick={() => setGenerateDialog(true)}>Click here to generate a new code</p>
                                    </div>
                                )
                            }
                        </div>
                    </Dialog>
                )
            }
            {
                otpauthURI && (
                    <Dialog
                        isOpen
                        onClose={() => setOtpauthURI(null)}
                        title="Set up multi-factor authentication"
                        className={mainStore.themeClass}
                        canOutsideClickClose={false}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <SchemaForm schema={TOTPAuthSchema()} />
                        </div>
                    </Dialog>
                )
            }

            {
                disable2FADialog.isShow && (
                    <Alert
                        confirmButtonText="Ok"
                        cancelButtonText="Cancel"
                        isOpen
                        onConfirm={async () => {
                            setDisable2FADialog({ ...disable2FADialog, loading: true })
                            await disable2FA()
                            setDisable2FADialog({
                                isShow: false,
                                loading: false
                            })
                        }}
                        loading={disable2FADialog.loading}
                        onCancel={() => setDisable2FADialog({ ...disable2FADialog, isShow: false })}
                    >
                        <div className='desc-disable-2FA'>
                            <p className='title'>Are you sure you want to disable 2FA?</p>
                            <p className='desc'>Keep in mind that login won't have to be confirmed with 2FA once it's disabled.</p>
                        </div>
                    </Alert>
                )
            }

            {
                generateDialog && (
                    <Alert
                        confirmButtonText="Ok"
                        cancelButtonText="Cancel"
                        isOpen
                        onConfirm={async () => {
                            await setupOTP()
                            setGenerateDialog(false)
                        }}
                        onCancel={() => setGenerateDialog(false)}
                    >
                        <div className='desc-disable-2FA'>
                            <p className='title'>Are you sure you want to generate?</p>
                            <p>Creating a new code requires scanning the qr again and the previous qr code is no longer valid.</p>
                        </div>
                    </Alert>
                )
            }
        </>
    )
});
