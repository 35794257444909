import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import {MainNav} from './main-nav';
import {OAuth} from "../../utils";
import {paths} from "../paths";
import {NotFound} from "../not-found";
import {PleaseWait} from "../please-wait";

import './layout.scss';
import { ChangePasswordDialog } from "../../components/change-password";
import {Dashboard} from "../dashboard";
import { AccountSettingDialog } from "../../components/account-setting";
import { TOTPAuthDialog } from '../../components/setup-TOTP';

function loadComponent(entry) {
    return entry.lazyLoad ? lazy(entry.lazyLoad) : entry.component;
}

export const Layout = () => {
    // React.useEffect(() => {
    //     window.onbeforeunload = function() {
    //         return 'Reload site? Changes that you made may not be saved.';
    //     };
    // });
    return [
        <MainNav key="main-nav"/>,
        <main key="docs-content-wrapper" className="docs-content-wrapper bp3-fill">
            <Suspense fallback={<PleaseWait/>}>
                <Switch>
                    {/* {paths.flatMap(p => OAuth.hasScope(p.scope) && p.sub.map(entry => <Route
                        key={entry.href}
                        path={entry.href}
                        component={loadComponent(entry)}
                    />))} */}
                    <Route path="/" component={Dashboard} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Suspense>
            <ChangePasswordDialog />            
            <AccountSettingDialog />
            <TOTPAuthDialog />
        </main>        
    ];
};