import { createContext } from 'react';
import { decorate, observable } from 'mobx';

class AuthStore {
    loggedIn = false;

    authUser = {};
    setAuthUser(authUser) {
        this.authUser = authUser;
    }

    //sign-in Content placed inside of the sign in workflow for when a user wants to sign into their account
    //confirm-sign-in Content placed inside of the confirm sign in workflow for when a user needs to confirm the account they signed in with
    //sign-up 	Content placed inside of the sign up workflow for when a user wants to register a new account
    //confirm-sign-up 	Content placed inside of the confirm sign up workflow for when a user needs to confirm the account they signed up with
    //forgot-password 	Content placed inside of the forgot password workflow for when a user wants to reset their password
    //require-new-password 	Content placed inside of the require new password workflow for when a user is required to update their password
    //verify-contact 	Content placed inside of the verify - contact workflow for when a user must verify their contact information
    //totp-setup 	Content placed inside of the totp - setup workflow for when a user opts to use TOTP MFA
    //greetings 	Content placed inside of the greetings navigation for when a user is signed in
    //loading 	Content placed inside of the loading workflow for when the app is loading
    authState = '';
    setAuthState(authState) {
        this.authState = authState;
    }
}

decorate(AuthStore, {
    loggedIn: observable,
    authState: observable,
    authUser: observable,
});

export const authStore = new AuthStore();

export const authStoreContext = createContext(authStore);

export const GroupAccessApp = {
    Administrators: 'Administrators',
    Dashboard: 'Dashboard',
    MAP_Administrator: 'MAP_Administrator',
    MAP_Merchant: 'MAP_Merchant'
};