import { Auth } from 'aws-amplify';
import { OAuth } from "../../utils/oauth";
import { history } from '../../utils';
import { handler } from '../../components/cognito/decode-verify-jwt';

const state = {
    accessKey: '',
    isValidToken: false
};

export const LogoutToken = () => {
    let params = new URLSearchParams(history.location.search);
    state.accessKey = params.get('access_token');    
    let localToken = Auth.currentSession();
  
    let handlerToken = handler(state.accessKey);
    handlerToken.then(function (result) {
        state.isValidToken = result.isValid;
    });

    localToken.then((data) => {
        if ((state.accessKey != null && state.accessKey === data.getAccessToken().getJwtToken()) || state.isValidToken) {
            OAuth.logout();
        }
    })
};