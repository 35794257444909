import { promisify } from 'util';
import * as Axios from 'axios';
import * as jsonwebtoken from 'jsonwebtoken';
import { GroupAccessApp } from '../../stores/auth-store';

const jwkToPem = require('jwk-to-pem');
const cognitoPoolId = process.env.REACT_APP_COGNITO_POOLS_ID || '';
if (!cognitoPoolId) {
    throw new Error('env var required for cognito pool');
}
const cognitoIssuer = `https://cognito-idp.ap-southeast-1.amazonaws.com/${cognitoPoolId}`;
let cacheKeys;
const getPublicKeys = async () => {
    if (!cacheKeys) {
        const url = `${cognitoIssuer}/.well-known/jwks.json`;
        const publicKeys = await Axios.default.get(url);
        cacheKeys = publicKeys.data.keys.reduce((agg, current) => {
            const pem = jwkToPem(current);
            agg[current.kid] = { instance: current, pem };
            return agg;
        }, {});
        return cacheKeys;
    }
    else {
        return cacheKeys;
    }
};
const verifyPromised = promisify(jsonwebtoken.verify.bind(jsonwebtoken));
const handler = async (request) => {
    let result;
    try {
        //console.log(`user claim verfiy invoked for ${JSON.stringify(request)}`);
        const token = request.token;
        const tokenSections = (token || '').split('.');
        if (tokenSections.length < 2) {
            throw new Error('requested token is invalid');
        }
        const headerJSON = Buffer.from(tokenSections[0], 'base64').toString('utf8');
        const header = JSON.parse(headerJSON);
        const keys = await getPublicKeys();
        const key = keys[header.kid];
        if (key === undefined) {
            throw new Error('claim made for unknown kid');
        }
        const claim = await verifyPromised(token, key.pem);
        const currentSeconds = Math.floor((new Date()).valueOf() / 1000);
        if (currentSeconds > claim.exp || currentSeconds < claim.auth_time) {
            throw new Error('claim is expired or invalid');
        }
        if (claim.iss !== cognitoIssuer) {
            throw new Error('claim issuer is invalid');
        }
        if (claim.token_use !== 'access' && claim.token_use !== 'id') {
            throw new Error('claim use is not access');
        }
        // if (claim["cognito:groups"] === undefined || !claim['cognito:groups'].includes(GroupAccessApp.MAP_Merchant)) {
        //     //console.log(claim['cognito:groups']);
        //     throw new Error('Claim role is not access. Please check with Administrator');
        // }

        result = { userName: claim.username, clientId: claim.client_id, isValid: true };
    }
    catch (error) {
        //console.log(error);
        result = { userName: '', clientId: '', error: error.message, isValid: false };
    }
    
    return result;
};
export { handler };