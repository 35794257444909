export const LOCAL_STORAGE = {
    THEME: 'THEME',
    OAUTH: {
        REFRESH_TOKEN: 'rt',
        EXPIRED_TIME: 'et',
        RT_EXPIRED_TIME: 'ret',
    },
    tokens: {
        accessToken: '',
        idToken: '',
        refreshToken: '',
        expiration: '',
    },
};
