import * as R from "ramda";

// import {SYSTEM} from '../constants';
import {Backend} from './backend';
import {alertStore, mainStore, cacheStore, spinnerStore} from '../stores';
const PRELOADS = {
  // '/preload/roles': data => cacheStore.setRoles(data.resultList),
  // '/user/get-auth-user': data => authStore.setAuthUser(data.result),
};

function errorCb(resp) {
  alertStore.add({
    icon: 'error',
    text: resp.data && resp.data.header ? `Backend Error\n\n${resp.data.header.errCode}: ${resp.data.header.errMsg}` : 'Unknown error',
    confirmButtonText: 'Reload',
    onClose: () => window.location.reload(),
  });
}

export const Preload = {
  loadEnvVars(cb) {
    Backend.get('/preload/getConfig', null, resp => {
      const {
        secure,
      } = resp.data.result;
      mainStore.secure = secure;
      cb();
    }, () => {
      alertStore.add({text: 'Load environment variables failed, please reload this page.'});
    }, false);
  },

  loadCaches(cb) {
    spinnerStore.inc();
    const entries = Object.entries(PRELOADS);
    (function load(i) {
      if (i === entries.length) {
        cb();
        spinnerStore.dec();
        return;
      }
      const [url, fn] = entries[i];
      Preload.loadCache(url, fn, () => load(i + 1));
    })(0);
  },

  loadCache(url, fn, cb) {
    Backend.get(url, null, resp => {
      fn(resp.data);
      cb && cb();
    }, errorCb, mainStore.secure);
  },

  // loadCountries() {
  //   if (R.isEmpty(cacheStore.countries)) {
  //     Preload.loadCache('/preload/getAllCountries', data => cacheStore.setCountries(data.resultList));
      
  //   }
  // },

};
